// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Employees_search__belMm {
  margin-bottom: 24px;
}
@media (min-width: 640px) {
  .Employees_search__belMm {
    margin-bottom: 31px;
  }
}

.Employees_grid__R70Ra {
  display: block;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 12px;
  margin-bottom: 12px;
}
@media (min-width: 768px) {
  .Employees_grid__R70Ra {
    display: grid;
  }
}
.Employees_grid--is-skeleton__RAl3f {
  margin-bottom: 0;
}

.Employees_skeleton__7GnDh,
.Employees_item__G4Zog {
  margin-bottom: 12px;
}
.Employees_skeleton__7GnDh:last-child,
.Employees_item__G4Zog:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .Employees_skeleton__7GnDh,
  .Employees_item__G4Zog {
    margin-bottom: 0;
  }
}

.Employees_skeleton__7GnDh {
  width: 100%;
  height: 164px;
  border-radius: 12px;
}
.Employees_skeleton--sm__49Zl7 {
  height: 34px;
  margin-bottom: 2px;
}

.Employees_nothing__nHudH {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 114%;
  margin: auto;
  width: 100%;
}
@media (min-width: 1200px) {
  .Employees_nothing__nHudH {
    margin: 20px auto;
    position: initial;
  }
}

.Employees_employee-profile-block__rhi2h {
  margin-top: 24px;
  min-height: 360px;
}

@media (max-width: 1200px) and (min-width: 640px) {
  .Employees_search-select__1QbvW {
    margin-top: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Employees/Employees.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;AACE;EAHF;IAII,mBAAA;EAEF;AACF;;AACA;EACE,cAAA;EACA,qCAAA;EACA,cAAA;EACA,mBAAA;AAEF;AAAE;EANF;IAOI,aAAA;EAGF;AACF;AADE;EACE,gBAAA;AAGJ;;AACA;;EAEE,mBAAA;AAEF;AAAE;;EACE,gBAAA;AAGJ;AAAE;EARF;;IASI,gBAAA;EAIF;AACF;;AADA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;AAIF;AAFE;EACE,YAAA;EACA,kBAAA;AAIJ;;AAAA;EACE,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,YAAA;EACA,WAAA;AAGF;AADE;EARF;IASI,iBAAA;IACA,iBAAA;EAIF;AACF;;AADA;EACE,gBAAA;EACA,iBAAA;AAIF;;AAAE;EADF;IAEI,gBAAA;EAIF;AACF","sourcesContent":[".search {\r\n  margin-bottom: 24px;\r\n\r\n  @media (min-width: 640px) {\r\n    margin-bottom: 31px;\r\n  }\r\n}\r\n\r\n.grid {\r\n  display: block;\r\n  grid-template-columns: repeat(2, 1fr);\r\n  grid-gap: 12px;\r\n  margin-bottom: 12px;\r\n\r\n  @media (min-width: 768px) {\r\n    display: grid;\r\n  }\r\n\r\n  &--is-skeleton {\r\n    margin-bottom: 0;\r\n  }\r\n}\r\n\r\n.skeleton,\r\n.item {\r\n  margin-bottom: 12px;\r\n\r\n  &:last-child {\r\n    margin-bottom: 0;\r\n  }\r\n\r\n  @media (min-width: 768px) {\r\n    margin-bottom: 0;\r\n  }\r\n}\r\n\r\n.skeleton {\r\n  width: 100%;\r\n  height: 164px;\r\n  border-radius: 12px;\r\n\r\n  &--sm {\r\n    height: 34px;\r\n    margin-bottom: 2px;\r\n  }\r\n}\r\n\r\n.nothing {\r\n  text-align: center;\r\n  font-size: 16px;\r\n  font-weight: 600;\r\n  line-height: 114%;\r\n  margin: auto;\r\n  width: 100%;\r\n\r\n  @media (min-width: 1200px) {\r\n    margin: 20px auto;\r\n    position: initial;\r\n  }\r\n}\r\n\r\n.employee-profile-block {\r\n  margin-top: 24px;\r\n  min-height: 360px;\r\n}\r\n\r\n.search-select {\r\n  @media (max-width: 1200px) and (min-width: 640px)  {\r\n    margin-top: 16px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search": `Employees_search__belMm`,
	"grid": `Employees_grid__R70Ra`,
	"grid--is-skeleton": `Employees_grid--is-skeleton__RAl3f`,
	"skeleton": `Employees_skeleton__7GnDh`,
	"item": `Employees_item__G4Zog`,
	"skeleton--sm": `Employees_skeleton--sm__49Zl7`,
	"nothing": `Employees_nothing__nHudH`,
	"employee-profile-block": `Employees_employee-profile-block__rhi2h`,
	"search-select": `Employees_search-select__1QbvW`
};
export default ___CSS_LOADER_EXPORT___;
