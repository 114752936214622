import { api } from "./index"
import { ICoordination } from "../../types/content"

interface IGetCoordinations {
  is_active?: boolean
  statuses?: string[]
  ordered_by?: string[]
  service_id?: string[]
  date?: { from: number; to: number }
  keywords?: string
  limit?: number
  offset?: number
}

interface IGetCoordinationsRes {
  aApprovals: ICoordination[]
  bIsEnd: boolean
  iCount: number
}

export const coordinationsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCoordinations: builder.query<IGetCoordinationsRes, IGetCoordinations>({
      query: (data) => {
        return {
          url: `/public/approval-routes/get-approvals`,
          body: {
            is_active: data.is_active,
            limit: data.limit,
            offset: data.offset,
            ordered_by: data.ordered_by,
            service_id: data.service_id,
            statuses: data.statuses,
            date: data.date,
            keywords: data.keywords,
          },
          method: "POST",
        }
      },
    }),

    setApproveCoordination: builder.mutation<void, { id: string; comment?: string; isApproved: boolean }>({
      query: (data) => ({
        url: `/public/approval-routes/sand-approval/${data.id}`,
        body: { comment: data?.comment, isApproved: data.isApproved },
        method: "POST",
      }),
    }),

    getCountActiveApprovals: builder.query<{ iCount: number }, void>({
      query: () => ({
        url: `public/approval-routes/count-active-approvals`,
      }),
    }),

    // Отметить согласование как прочитанное
    setViewCoordination: builder.mutation<void, string>({
      query: (id) => ({
        url: `/public/approval-routes/view-approval/${id}`,
        method: "POST",
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useLazyGetCoordinationsQuery,
  useSetApproveCoordinationMutation,
  useLazyGetCountActiveApprovalsQuery,
  useSetViewCoordinationMutation,
} = coordinationsApi
