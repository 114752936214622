import { FC, Fragment, useEffect, useMemo, useState } from "react"
import OrderModalCommonBlock from "./OrderModalCommonBlock"
import ConditionList from "./ConditionList"
import { TOrderOptions } from "../../../types/orderTypes"
import { useAppSelector } from "../../../hooks"
import { currentObject } from "../../../redux/slice/object"
import InfoModal from "../InfoModal/InfoModal"
import { PhotoCameraIcon } from "../../../icons/PhotoCameraIcon"
import { useTranslation } from "react-i18next"
import { getIsAllowCameraModal, setIsAllowCameraModal } from "../../../redux/slice/modals"
import { useDispatch } from "react-redux"

interface IOptionsList {
  optionsItems: TOrderOptions
  setError?: (val: boolean) => void
  optionsRef?: any
  setValidateIds: any
}

const OptionsList: FC<IOptionsList> = ({ optionsItems, setError, optionsRef, setValidateIds }) => {
  const curObj = useAppSelector(currentObject)
  const dispatch = useDispatch()
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const isAllowCameraModal = useAppSelector(getIsAllowCameraModal)
  // Стейт для отображения данной модалки всего 1 раз
  const [isNotNeedinfoModal, setIsNotNeedInfoModal] = useState(true)

  // Вычисление первого QR инпута, что бы выставить время
  const firstQrDateIndex = useMemo((): number => {
    return optionsItems.findIndex((item) => item.type === "qr")
  }, [optionsItems])

  useEffect(() => {
    const requiredItems = optionsItems.filter((el) => el.required)
    const isRequairedIds = requiredItems?.map((ell) => ell.id)

    setValidateIds((pr: any[]) => {
      return [...pr, ...requiredItems]
    })

    return () => {
      if (isRequairedIds?.length) {
        setValidateIds((pr: any[]) => {
          return [...pr.filter((el) => !isRequairedIds.includes(el.id))]
        })
      }
    }
  }, [])

  useEffect(() => {
    if (optionsRef?.current) {
      setTimeout(() => {
        optionsRef?.current?.scrollIntoView({ block: "center", behavior: "smooth" })
      }, 300)
    }
  }, [optionsRef])

  return (
    <>
      {Array.isArray(optionsItems) && optionsItems.length > 0 ? (
        <div ref={optionsRef} className="options-list">
          {optionsItems.map((el, i) => {
            return (
              <Fragment key={el.id}>
                <OrderModalCommonBlock
                  el={el}
                  setError={setError}
                  isFirstQrDate={firstQrDateIndex === i && curObj.QRScanDate ? curObj.QRScanDate : null}
                />
                {el?.conditions?.length > 0 && (
                  <ConditionList
                    onlyDate={el.type === "datetime" && el?.only_date}
                    maxNumber={el.type === "number" && el?.max}
                    minNumber={el.type === "number" && el?.min}
                    elType={el.type}
                    elId={el.id}
                    conditionList={el?.conditions}
                    setValidateIds={setValidateIds}
                  />
                )}
              </Fragment>
            )
          })}
        </div>
      ) : (
        <></>
      )}
      {isNotNeedinfoModal && (
        <InfoModal
          icon={<PhotoCameraIcon className={"camera"} />}
          modalText={t("allowUsedCamera")}
          setOpen={() => {
            dispatch(setIsAllowCameraModal(false))
            setIsNotNeedInfoModal(false)
          }}
          open={isAllowCameraModal}
        />
      )}
    </>
  )
}

export default OptionsList
