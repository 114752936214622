import { Fragment } from "react"
import ChatDay from "../Modals/ChatModal/ChatDay"
import ChatMessage from "../Modals/ChatModal/ChatMessage"
import { IChatMessage } from "../../types/chat"
import { useAppSelector } from "../../hooks"
import { selectUser } from "../../redux/slice/auth"
import { useTranslation } from "react-i18next"
import styles from "../Modals/ChatModal/ChatModal.module.scss"

interface Props {
  messages: IChatMessage[]
  idNewMess: any
  isFirstIdNewMess: any
  openPhoto: (photoInfo: any, kk: number) => void
}

const ChatMessages = ({ messages, idNewMess, isFirstIdNewMess, openPhoto }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const nowUser = useAppSelector(selectUser)

  return (
    <div className={styles.chatModal__mainList}>
      {messages?.map((el, kk) => {
        if (
          kk !== 0 &&
          idNewMess.current === false &&
          isFirstIdNewMess.current !== true &&
          el?.is_readed === false &&
          el?.user?.id !== nowUser.id
        ) {
          idNewMess.current = el.id
          isFirstIdNewMess.current = false
        }

        return (
          <Fragment key={el.id}>
            {idNewMess.current === el.id && (
              <div className={styles.chatModal__newMessage}>
                <span>{t("newMessage")}</span>
              </div>
            )}
            <ChatDay prevEl={messages[kk - 1] || false} nowEl={el} />
            <ChatMessage message={el} nowUserId={nowUser?.id} openPhoto={openPhoto} />
          </Fragment>
        )
      })}
    </div>
  )
}

export default ChatMessages
