import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../store"
import { IChatInfo } from "../../types/chat"

export interface modalSliceState {
  messagesModalIsOpen: boolean
  messagesModalFirstChat: IChatInfo | null
  isOrderModalOpen: boolean
  isAllowCameraModal: boolean
}

const initialState: modalSliceState = {
  messagesModalIsOpen: false,
  messagesModalFirstChat: null,
  isOrderModalOpen: false,
  isAllowCameraModal: false,
}

export const ModalSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    setMessagesModalIsOpen: (state: modalSliceState, action: PayloadAction<boolean>) => {
      state.messagesModalIsOpen = action.payload
    },
    setMessagesModalFirstChat: (state: modalSliceState, action: PayloadAction<IChatInfo | null>) => {
      state.messagesModalFirstChat = action.payload
    },
    setOrderModalOpen: (state: modalSliceState, action: PayloadAction<boolean>) => {
      state.isOrderModalOpen = action.payload
    },
    setIsAllowCameraModal: (state: modalSliceState, action: PayloadAction<boolean>) => {
      state.isAllowCameraModal = action.payload
    },
  },
})

export const { setMessagesModalIsOpen, setMessagesModalFirstChat, setOrderModalOpen, setIsAllowCameraModal } =
  ModalSlice.actions

export const getMessagesModal = (state: RootState) => state[ModalSlice.name].messagesModalIsOpen
export const getMessagesModalFirstChat = (state: RootState) => state[ModalSlice.name].messagesModalFirstChat
export const selectModals = (state: any): modalSliceState => state[ModalSlice.name]
export const getIsAllowCameraModal = (state: RootState) => state[ModalSlice.name].isAllowCameraModal
