// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CoordinationStatusLabel_status-label__yDwvv {
  padding: 6px 12px;
  display: flex;
  gap: 2px;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  border-radius: 40px;
  margin-left: auto;
  align-items: center;
  height: 28px;
}
.CoordinationStatusLabel_status-label__yDwvv svg {
  width: 16px;
}

.CoordinationStatusLabel_status-label-res__PkYS9 {
  background: rgba(45, 190, 100, 0.1215686275);
  color: var(--accent-color);
}

.CoordinationStatusLabel_status-label-rej__rzJI9 {
  background: rgba(245, 77, 77, 0.1215686275);
  color: #F54E4C;
}

.CoordinationStatusLabel_status-label-load__DZxvZ {
  background: #DBECFA;
  color: #177FD2;
}`, "",{"version":3,"sources":["webpack://./src/components/Coordinations/CoordinationStatusLabel/CoordinationStatusLabel.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,aAAA;EACA,QAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,iBAAA;EACA,mBAAA;EACA,YAAA;AACF;AACE;EACE,WAAA;AACJ;;AAGA;EACE,4CAAA;EACA,0BAAA;AAAF;;AAGA;EACE,2CAAA;EACA,cAAA;AAAF;;AAGA;EACE,mBAAA;EACA,cAAA;AAAF","sourcesContent":[".status-label {\n  padding: 6px 12px;\n  display: flex;\n  gap: 2px;\n  font-size: 12px;\n  font-weight: 500;\n  line-height: 12px;\n  border-radius: 40px;\n  margin-left: auto;\n  align-items: center;\n  height: 28px;\n\n  svg {\n    width: 16px;\n  }\n}\n\n.status-label-res {\n  background: #2DBE641F;\n  color: var(--accent-color);\n}\n\n.status-label-rej {\n  background: #F54D4D1F;\n  color: #F54E4C;\n}\n\n.status-label-load {\n  background: #DBECFA;\n  color: #177FD2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status-label": `CoordinationStatusLabel_status-label__yDwvv`,
	"status-label-res": `CoordinationStatusLabel_status-label-res__PkYS9`,
	"status-label-rej": `CoordinationStatusLabel_status-label-rej__rzJI9`,
	"status-label-load": `CoordinationStatusLabel_status-label-load__DZxvZ`
};
export default ___CSS_LOADER_EXPORT___;
