import clsx from "clsx"

export const LoupeIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={clsx(className)}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667C8.56581 12.6667 9.70064 12.2486 10.6038 11.5466L12.862 13.8049C13.1224 14.0652 13.5445 14.0652 13.8049 13.8049C14.0652 13.5445 14.0652 13.1224 13.8049 12.862L11.5466 10.6038C12.2486 9.70064 12.6667 8.56581 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2ZM3.33333 7.33333C3.33333 5.12419 5.12419 3.33333 7.33333 3.33333C9.54247 3.33333 11.3333 5.12419 11.3333 7.33333C11.3333 9.54247 9.54247 11.3333 7.33333 11.3333C5.12419 11.3333 3.33333 9.54247 3.33333 7.33333Z"
      />
    </svg>
  )
}
