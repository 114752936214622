// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LabelInfo_labelInfo__bPlKm {
  padding: 6px 12px;
  display: flex;
  gap: 2px;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  border-radius: 40px;
  align-items: center;
  height: 28px;
  background: #DBECFA;
  color: #177FD2;
  flex-shrink: 0;
}
.LabelInfo_labelInfo__iconWrap__HwxbQ {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.LabelInfo_labelInfo__iconWrap__HwxbQ svg {
  max-width: 100%;
  max-height: 100%;
}
.LabelInfo_labelInfo--success__-V13y {
  background: rgba(45, 190, 100, 0.1215686275);
  color: var(--accent-color);
}
.LabelInfo_labelInfo--danger__kYSFY {
  background: rgba(245, 77, 77, 0.1215686275);
  color: #F54E4C;
}`, "",{"version":3,"sources":["webpack://./src/components/Assets/LabelInfo/LabelInfo.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,aAAA;EACA,QAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,mBAAA;EACA,YAAA;EACA,mBAAA;EACA,cAAA;EACA,cAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;EACA,cAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;AACI;EACE,eAAA;EACA,gBAAA;AACN;AAGE;EACE,4CAAA;EACA,0BAAA;AADJ;AAIE;EACE,2CAAA;EACA,cAAA;AAFJ","sourcesContent":[".labelInfo {\r\n  padding: 6px 12px;\r\n  display: flex;\r\n  gap: 2px;\r\n  font-size: 12px;\r\n  font-weight: 500;\r\n  line-height: 12px;\r\n  border-radius: 40px;\r\n  align-items: center;\r\n  height: 28px;\r\n  background: #DBECFA;\r\n  color: #177FD2;\r\n  flex-shrink: 0;\r\n\r\n  &__iconWrap {\r\n    width: 16px;\r\n    height: 16px;\r\n    flex-shrink: 0;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n\r\n    svg {\r\n      max-width: 100%;\r\n      max-height: 100%;\r\n    }\r\n  }\r\n\r\n  &--success {\r\n    background: #2DBE641F;\r\n    color: var(--accent-color);\r\n  }\r\n\r\n  &--danger {\r\n    background: #F54D4D1F;\r\n    color: #F54E4C;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labelInfo": `LabelInfo_labelInfo__bPlKm`,
	"labelInfo__iconWrap": `LabelInfo_labelInfo__iconWrap__HwxbQ`,
	"labelInfo--success": `LabelInfo_labelInfo--success__-V13y`,
	"labelInfo--danger": `LabelInfo_labelInfo--danger__kYSFY`
};
export default ___CSS_LOADER_EXPORT___;
