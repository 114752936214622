import { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import styles from "./OrdersList.module.scss"
import OrderItem from "../OrderItem/OrderItem"
import moment from "moment"
import { useTranslation } from "react-i18next"
import OrdersSkeleton from "./OrdersSkeleton"
import { IServiceOrderShort } from "../../types/content"
import { useAppSelector } from "../../hooks"
import { selectUser } from "../../redux/slice/auth"
import { Channel } from "laravel-echo"
import { ILastMessage } from "../../types/chat"

interface Props {
  clickFcn?: any
  orders: IServiceOrderShort[]
  isLoading?: boolean
  isFirstRender?: boolean
  isLoadingAppend?: boolean
  layout?: "history" | "active"
  ratingFn?: (order: any) => void
  setOrders?: Dispatch<
    SetStateAction<{
      orders: IServiceOrderShort[]
      isEnd: boolean
    }>
  >
  orderInfoModal?: boolean
  openedOrder?: string
}

const OrdersList: FC<Props> = ({
  clickFcn,
  orders,
  isLoading,
  isFirstRender,
  isLoadingAppend,
  layout,
  ratingFn,
  setOrders,
  orderInfoModal,
  openedOrder,
}) => {
  const [editedArr, setEditedArr] = useState<{ date: Date; orders: IServiceOrderShort[] }[]>([])
  const { t } = useTranslation("translation", { keyPrefix: `interface` })

  const user = useAppSelector(selectUser)

  const createOrdersArrWithDate = (orders: IServiceOrderShort[]) => {
    const newArr: { date: Date; orders: IServiceOrderShort[] }[] = []

    const createNewObj = (obj: IServiceOrderShort) => {
      const newObj: any = {}
      newObj.date = `${moment(obj.created_at)}`
      newObj.orders = [obj]
      newArr.push(newObj)
    }

    orders.forEach((el) => {
      if (newArr.length === 0) {
        createNewObj(el)
      } else {
        newArr.forEach((nr, i) => {
          if (moment(new Date(nr.date).toISOString()).isSame(el.created_at, "day")) {
            newArr[i].orders.push(el)
          } else if (i === newArr.length - 1) {
            createNewObj(el)
          }
        })
      }
    })
    return newArr
  }

  useEffect(() => {
    setEditedArr(createOrdersArrWithDate(orders))
  }, [orders])

  useEffect(() => {
    if (!user?.id) return
    const channel: Channel = window.Echo?.private(`privateUser.${user?.id}`)
    channel?.listen(".deleteMessageInDialog", (event: ILastMessage) => {
      if (event.is_readed) return
      //Если сообщение еще не было прочитанным, то уменьшаем
      if (setOrders) {
        setOrders((prev) => {
          const index = prev.orders.findIndex((i) => i.chat_dialog_id === event.chat_dialog_id)
          const cloneOrders = [...prev.orders]
          if (
            (index !== 0 && !index) ||
            !cloneOrders[index] ||
            (orderInfoModal && openedOrder === cloneOrders[index].id)
          ) {
            return prev
          }
          cloneOrders[index] = {
            ...cloneOrders[index],
            message_counts:
              cloneOrders[index].message_counts > 0
                ? cloneOrders[index].message_counts - 1
                : cloneOrders[index].message_counts,
          }
          return { ...prev, orders: cloneOrders }
        })
      }
    })
    return () => {
      // if (!openedOrder) channel?.stopListening(".deleteMessageInDialog")
    }
  }, [user])

  return (
    <>
      {isLoading || isFirstRender ? (
        <OrdersSkeleton isMainLoad />
      ) : (
        <>
          {editedArr.length ? (
            <div className={styles.ordersList}>
              {editedArr.map((el, i: number) => (
                <div className={styles.ordersList__day} key={i}>
                  <div className={styles.ordersList__title}>
                    {moment(new Date(el.date).toISOString()).format("DD MMMM YYYY")}
                  </div>
                  <div className={styles.ordersList__list}>
                    {el.orders.map((ell, ii: number) => (
                      <OrderItem
                        {...ell}
                        key={ii}
                        ratingFn={() => (ratingFn ? ratingFn(ell.id) : null)}
                        clickFcn={() => clickFcn(ell)}
                        layout={layout}
                      />
                    ))}
                  </div>
                </div>
              ))}
              {isLoadingAppend ? <OrdersSkeleton /> : <></>}
            </div>
          ) : (
            <p className={styles.nothingContent}>{t("actOrHistOrdersNotFound")}</p>
          )}
        </>
      )}
    </>
  )
}

export default OrdersList
