import { useMemo } from "react"
import { ProgressCircle } from "../../../icons/ProgressCircle"
import { DoubleCheckIcon } from "../../../icons/DoubleCheckIcon"
import { CrossIcon } from "../../../icons/CrossIcon"
import clsx from "clsx"
import styles from "./LabelInfo.module.scss"

interface Props {
  txt: string
  icon?: "approved" | "denied" | "waiting" | JSX.Element
  color?: "success" | "danger"
  className?: string
}

const LabelInfo = ({ txt, icon, color, className }: Props) => {
  const currentIcon = useMemo(() => {
    if (!icon) return null
    switch (icon) {
      case "approved":
        return (
          <span className={styles.labelInfo__iconWrap}>
            <DoubleCheckIcon />
          </span>
        )
      case "denied":
        return (
          <span className={styles.labelInfo__iconWrap}>
            <CrossIcon />
          </span>
        )
      case "waiting":
        return (
          <span className={styles.labelInfo__iconWrap}>
            <ProgressCircle />
          </span>
        )
      default:
        return <span className={styles.labelInfo__iconWrap}>{icon}</span>
    }
  }, [icon])

  return (
    <div className={clsx(styles.labelInfo, color && styles["labelInfo--" + color], className)}>
      {currentIcon}
      {txt}
    </div>
  )
}

export default LabelInfo
