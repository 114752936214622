import Search from "../Search/Search"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import _debounce from "lodash/debounce"
import { ICoordination, IEmployee } from "../../types/content"
import clsx from "clsx"
import TitleBack from "../TitleBack/TitleBack"
import { useTranslation } from "react-i18next"
import styles from "./Coordinations.module.scss"
import Coordination from "./Coordination/Coordination"
import { useLazyGetCoordinationsQuery } from "../../redux/api/coordinations"
import CoordinationsFilters from "./CoordinationsFilters/CoordinationsFilters"
import { IOrderShort } from "../../types/orderTypes"
import useWindowSize from "../../hooks/useWindowSize"
import Button from "../Assets/Button/Button"
import { FilterIcon } from "../../icons/FilterIcon"
import { countActiveCoordinations } from "../../redux/slice/main"
import { useAppSelector } from "../../hooks"
import OrderInfoModal from "../Modals/OrderInfoModal/OrderInfoModal"

interface Props {
  className?: string
}

export interface IStatusFilter {
  id: number
  name: string
  value: string
}

const Coordinations = ({ className }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const { isDesktop } = useWindowSize()
  const quantityCountCoordinations = useAppSelector(countActiveCoordinations)

  //  АПИ
  const [
    getActiveCoordinations,
    {
      isLoading: activeIsLoading,
      data: activeCoordinations,
      isUninitialized: isActiveUninitialized,
      // isFetching: activeIsFetching,
    },
  ] = useLazyGetCoordinationsQuery()
  const [
    getStoryCoordinations,
    {
      isLoading: storyIsLoading,
      data: storyCoordinations,
      isUninitialized: isStoryUninitialized,
      isFetching: isStoryFetching,
    },
  ] = useLazyGetCoordinationsQuery()

  const [searchCoordination, setSearchCoordination] = useState<string>("")
  const [offsetStoryCoordinations, setOffsetStoryCoordinations] = useState<number>(0)
  const [storyItems, setStoryItems] = useState<ICoordination[]>([])
  const loaderRef = useRef<HTMLDivElement>(null)
  const [isSearchLoading, setSearchLoading] = useState<boolean>(false)
  const [isListEnd, setListEnd] = useState<boolean>(false)
  const [isOpenFilters, setIsOpenFilters] = useState<boolean>(false)

  // Стейты для модалки информации о заявке
  const [openedOrder, setOpenedOrder] = useState<string | undefined>(undefined)
  const [isOpenInfoModal, setIsOpenInfoModal] = useState<boolean>(false)
  const [chatID, setChatID] = useState<string>("")

  // Стейты для фильтров
  const [selectedUsers, setSelectedUsers] = useState<IEmployee[]>([])
  const [selectedCategory, setSelectedCategory] = useState<IOrderShort[]>([])
  const [selectedStatuses, setSelectedStatuses] = useState<IStatusFilter[]>([])
  const [initiationDate, setInitiationDate] = useState<number[]>([])

  // Рендер
  const list = useCallback(
    (coordList: ICoordination[]) => {
      if (!coordList?.length) return null
      return coordList.map((coordination: ICoordination) => {
        return (
          <Coordination
            key={coordination.id}
            {...coordination}
            className={clsx(styles.item, className)}
            reFetch={reFetch}
            setOpenedOrder={setOpenedOrder}
            setIsOpenInfoModal={setIsOpenInfoModal}
            setChatID={setChatID}
          />
        )
      })
    },
    [activeCoordinations?.aApprovals, storyItems],
  )

  const SkeletonContainer = ({ addClass }: { addClass?: boolean }) => {
    return (
      <div className={clsx(styles["skeleton-container"], addClass && styles["margined"])}>
        <div className={"skeletonBlock"} />
        <div className={"skeletonBlock"} />
        <div className={"skeletonBlock"} />
      </div>
    )
  }

  const fetchProps = useMemo(() => {
    return {
      ordered_by: [...selectedUsers.map((user) => user.id)],
      service_id: [...selectedCategory.map((category) => category.id)],
      statuses: [...selectedStatuses.map((status) => status.value)],
      date: { from: initiationDate[0], to: initiationDate[1] },
      keywords: searchCoordination,
    }
  }, [selectedUsers, selectedCategory, selectedStatuses, initiationDate, searchCoordination])

  const isViewSkeleton = useMemo(() => {
    return isActiveUninitialized || isStoryUninitialized || isSearchLoading || storyIsLoading || activeIsLoading
  }, [isActiveUninitialized, isStoryUninitialized, isSearchLoading, activeIsLoading, storyIsLoading])

  const fetchActiveData = useCallback(async () => {
    await getActiveCoordinations({ is_active: true, ...fetchProps })
  }, [fetchProps])

  const fetchStoryData = useCallback(
    async (isStart?: boolean) => {
      await getStoryCoordinations({
        limit: 10,
        offset: isStart ? 0 : offsetStoryCoordinations * 10,
        is_active: false,
        ...fetchProps,
      }).then((res) => {
        if (res?.data) {
          setListEnd(res.data.bIsEnd)

          // @ts-ignore
          setStoryItems((prev) => (isStart ? [...res.data.aApprovals] : [...prev, ...res.data.aApprovals]))
        }
        setOffsetStoryCoordinations((prev) => (isStart ? 0 : prev) + 1)
      })
    },
    [fetchProps, offsetStoryCoordinations],
  )

  const debounceFn = useCallback(
    _debounce(async () => {
      if (isActiveUninitialized || isStoryUninitialized) return
      setListEnd(false)

      reFetch()
    }, 500),
    [isSearchLoading, isActiveUninitialized, isStoryUninitialized, fetchProps],
  )

  const observer = new IntersectionObserver((entries) => {
    const target = entries[0]
    if (target.isIntersecting) {
      if (isStoryFetching || isSearchLoading) return
      fetchStoryData()
    }
  })

  const reFetch = async (noLoading?: boolean) => {
    if (!noLoading) {
      setSearchLoading(true)
    }
    fetchActiveData()
    fetchStoryData(true)
    if (!noLoading) {
      setSearchLoading(false)
    }
  }

  useEffect(() => {
    reFetch(true)
  }, [selectedUsers, selectedCategory, selectedStatuses, initiationDate, quantityCountCoordinations])

  useEffect(() => {
    debounceFn()

    return () => {
      debounceFn.cancel()
    }
  }, [searchCoordination])

  useEffect(() => {
    if (isStoryUninitialized) return
    if (!loaderRef.current) return
    if (isListEnd) return

    if (loaderRef.current) {
      observer.observe(loaderRef.current)
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current)
        observer.disconnect()
      }
    }
  }, [isStoryUninitialized, storyIsLoading, isSearchLoading, storyItems, loaderRef.current, observer, isListEnd])

  return (
    <>
      <div className={styles["header-container"]}>
        <TitleBack title={t("coordinations")} className={styles["title-back"]} />
        {!isDesktop && (
          <Button
            className={clsx(styles["open-filters"], isOpenFilters && styles["opened"])}
            onClick={() => setIsOpenFilters((prev) => !prev)}
            txt=""
            icon={<FilterIcon />}
          />
        )}
      </div>

      {(isDesktop || (!isDesktop && isOpenFilters)) && (
        <CoordinationsFilters
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          selectedStatuses={selectedStatuses}
          setSelectedStatuses={setSelectedStatuses}
          initiationDate={initiationDate}
          setInitiationDate={setInitiationDate}
        />
      )}

      <Search searchVal={searchCoordination} setSearchVal={setSearchCoordination} className={styles.search} />
      {isViewSkeleton ? (
        <SkeletonContainer />
      ) : !activeCoordinations?.aApprovals.length && !storyItems?.length && !storyCoordinations?.aApprovals.length ? (
        <div className={styles.nothing}>{t("noCoordinationYet")}</div>
      ) : (
        <>
          {activeCoordinations && activeCoordinations.aApprovals.length > 0 && (
            <div className={styles["coordination-container"]}>
              <h4 className={styles["title"]}>{t("active")}</h4>
              <div className={clsx(styles["list-container"])}>{list(activeCoordinations.aApprovals)}</div>
            </div>
          )}
          {storyItems && storyItems?.length > 0 && (
            <div className={styles["coordination-container"]}>
              <h4 className={styles["title"]}>{t("history")}</h4>
              <div className={clsx(styles["list-container"])}>{list(storyItems)}</div>
              <div ref={loaderRef} className={clsx(styles.nothingListContainer)}>
                {(isSearchLoading || isStoryFetching) && <SkeletonContainer addClass />}
              </div>
            </div>
          )}
        </>
      )}

      {isOpenInfoModal && openedOrder && (
        <OrderInfoModal
          id={openedOrder}
          chatID={chatID}
          open={isOpenInfoModal}
          setOpen={setIsOpenInfoModal}
          layout={"coordination"}
          modalSize="lg"
        />
      )}
    </>
  )
}

export default Coordinations
