import Search from "../Search/Search"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import _debounce from "lodash/debounce"
import { IEmployee } from "../../types/content"
import Employee from "./Employee/Employee"
import clsx from "clsx"
import TitleBack from "../TitleBack/TitleBack"
import { useLazyGetUsersQuery } from "../../redux/api/content"
// import ScrollBlock from "../Assets/ScrollBlock"
import { useTranslation } from "react-i18next"
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll"
import { useAppSelector } from "../../hooks"
import { selectUser } from "../../redux/slice/auth"
import styles from "./Employees.module.scss"
import SearchSelect from "../SearchSelect/SearchSelect"
import ProfileBlock from "../ProfileBlock/ProfileBlock"

interface Props {
  isShort?: boolean
  onClick?: (userID: string, name: string, avatar_id?: string) => void
  className?: string
}

const LIMIT_USERS = 20

const ListLoader = ({ isShort }: { isShort?: boolean }) => {
  return (
    <div className={clsx("employees__grid", styles.grid, styles["grid--is-skeleton"])}>
      {[...Array(4)].map((_item, index) => (
        <div key={index} className={clsx(styles.skeleton, isShort && styles["skeleton--sm"], "skeletonBlock")} />
      ))}
    </div>
  )
}

const Employees = ({ isShort, onClick, className }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const [getUsers, { isFetching, isLoading: isUsersLoading, isUninitialized }] = useLazyGetUsersQuery()
  const [getUsersForSelect, { isFetching: selectFetching, isLoading: selectIsUsersLoading }] = useLazyGetUsersQuery()
  const user = useAppSelector(selectUser)

  const [searchChat, setSearchChat] = useState<string>("")
  const [items, setItems] = useState<IEmployee[]>([])
  const [offset, setOffset] = useState<number>(0)
  const loaderRef = useRef<HTMLDivElement>(null)
  const [isSearchLoading, setSearchLoading] = useState<boolean>(false)
  const [isAllowFetched, setAllowFetched] = useState<boolean>(false)
  const [isListEnd, setListEnd] = useState<boolean>(false)
  const [searchedUser, setSearchedUser] = useState<IEmployee>()

  // const infiniteScroll = useInfiniteScroll<HTMLDivElement>(
  //   async () => {
  //     if (isListEnd || isFetching || !isShort) return
  //     await new Promise((resolve) => {
  //       setTimeout(resolve, 1000)
  //       void fetchData(false)
  //     })
  //   },
  //   { distance: 10, isScrollPlugin: isShort },
  // )

  useEffect(() => {
    if (isShort) return
    getUsers({ limit: LIMIT_USERS, department_id: user.department_id }).then((res) => {
      if (res?.data) {
        setItems(() => res.data?.aUsers || [])
        setListEnd(res.data.bIsEnd)
        setAllowFetched(true)
      }
    })
  }, [])

  const fetchData = async (isStart?: boolean) => {
    if (isListEnd) return
    if (isFetching || !isAllowFetched) return
    setOffset((prev) => (isStart ? 0 : prev + LIMIT_USERS))
    setAllowFetched(false)
    await getUsers({
      limit: LIMIT_USERS,
      search: searchChat,
      offset: isStart ? 0 : offset,
      department_id: user.department_id,
    }).then(({ data }) => {
      if (data?.aUsers) {
        setItems((prevItems) => {
          return !isStart && offset ? [...prevItems, ...data.aUsers] : data?.aUsers || []
        })
        setListEnd(data.bIsEnd)
      }
    })
    setSearchLoading(false)
    setTimeout(() => {
      setAllowFetched(true)
    }, 0)
  }

  const list = useMemo(() => {
    if (!items?.length) return null
    return items.map((employee) => {
      if (isShort && employee.id === user?.id) return null
      return (
        <Employee
          key={employee.id}
          {...employee}
          highlightTxt={searchChat}
          className={clsx(styles.item, className)}
          size={isShort ? "sm" : undefined}
          onClick={onClick}
        />
      )
    })
  }, [items, searchChat, isShort, onClick, user?.id])

  useEffect(() => {
    if (isUninitialized || isShort) return
    const observer = new IntersectionObserver((entries) => {
      const target = entries[0]
      if (target.isIntersecting) {
        if (isFetching || isSearchLoading || !isAllowFetched) return
        void fetchData(false)
      }
    })

    if (loaderRef.current) {
      observer.observe(loaderRef.current)
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current)
        observer.disconnect()
      }
    }
  }, [isUninitialized, isFetching, isUsersLoading, isSearchLoading, isAllowFetched, list, isShort])

  const debounceFn = useCallback(
    _debounce((str: string) => {
      if (isUninitialized) return
      if (!isAllowFetched) return
      if (isSearchLoading) return
      setSearchLoading(true)
      getUsers({ limit: LIMIT_USERS, search: str, offset: 0, department_id: user.department_id }).then(({ data }) => {
        if (data?.aUsers) {
          setItems(() => data?.aUsers || [])
          setSearchLoading(false)
          setListEnd(data.bIsEnd)
          setTimeout(() => {
            setAllowFetched(true)
          }, 0)
        }
      })
    }, 500),
    [isUninitialized, isAllowFetched, isSearchLoading],
  )

  useEffect(() => {
    setOffset(0)
    debounceFn(searchChat)
  }, [searchChat])

  return (
    <>
      {!isShort && (
        <TitleBack
          noLinkBack
          title={t("employees")}
          onClick={
            searchedUser
              ? () => {
                  setSearchedUser(undefined)
                }
              : undefined
          }
        />
      )}

      {/* <Search searchVal={searchChat} setSearchVal={setSearchChat} className={styles.search} /> */}
      {((!isShort && !searchedUser) || isShort) && (
        <SearchSelect
          setSelectedVal={setSearchedUser}
          selectedVal={searchedUser}
          searchReq={getUsersForSelect}
          isFetching={selectFetching}
          isLoading={selectIsUsersLoading}
          dataKey={"aUsers"}
          isShortClick={isShort && onClick}
          isShort={isShort}
          className={styles["search-select"]}
        />
      )}

      <>
        {/* {isShort ? (
          <ScrollBlock
            //@ts-ignore
            ref={infiniteScroll.ref}
            hideTracksWhenNotNeeded
            scrollBarSize={4}
            autoHeightMax={145}
          >
            {!isUninitialized && !isSearchLoading && (
              <>
                {list && list?.length > 0 ? (
                  <div className={clsx("employees__grid", styles.grid)}>{list}</div>
                ) : (
                  <>
                    {!isSearchLoading && !isFetching && !isUsersLoading && (
                      <div className={styles.nothing}>{t("noEmployeesYet")}</div>
                    )}
                  </>
                )}
              </>
            )}

            {list?.length && (infiniteScroll.isLoading || isFetching) && <ListLoader isShort />}
            {isFetching && !list?.length && <ListLoader isShort />}
          </ScrollBlock>
        ) : ( */}
        {!isShort && (
          <>
            {!isUninitialized && !isSearchLoading && (
              <>
                {searchedUser ? (
                  <ProfileBlock
                    profileData={searchedUser}
                    notViewPhones
                    isWriteMes={user.id !== searchedUser.id}
                    className={styles["employee-profile-block"]}
                  />
                ) : (
                  <>
                    {list && list?.length > 0 ? (
                      <div className={clsx("employees__grid", styles.grid)}>{list}</div>
                    ) : (
                      <>
                        {!isSearchLoading && !isFetching && !isUsersLoading && (
                          <div className={styles.nothing}>{t("noEmployeesYet")}</div>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}

            <div ref={loaderRef} className={clsx("employees__grid", styles.grid)}>
              {(isSearchLoading || isFetching) &&
                [...Array(4)].map((_item, index) => (
                  <div key={index} className={clsx(styles.skeleton, "skeletonBlock")} />
                ))}
            </div>
          </>
        )}
      </>
    </>
  )
}

export default Employees
