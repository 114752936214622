import { ChangeEvent, Dispatch, FC, SetStateAction } from "react"
import { useTranslation } from "react-i18next"
import { selectUser } from "../../redux/slice/auth"
import { useAppSelector } from "../../hooks"
import { LoupeIcon } from "../../icons/LoupeIcon"
import { CloseCircleIcon2 } from "../../icons/CloseCircleIcon2"
import clsx from "clsx"
import styles from "./Search.module.scss"

interface Props {
  searchVal: string | number
  setSearchVal: Dispatch<SetStateAction<any>>
  isLoading?: boolean
  withClear?: boolean
  className?: string
}

const Search: FC<Props> = ({ searchVal, setSearchVal, isLoading, withClear, className }) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const user = useAppSelector(selectUser)

  const handleClear = () => {
    setSearchVal("")
  }

  return (
    <div
      className={clsx(
        "searchBlock",
        styles.search,
        className,
        isLoading && `selectBack ${styles["search--is-loading"]}`,
        withClear && styles["search--with-clear"],
      )}
    >
      {!isLoading && (
        <>
          <input
            className={styles.search__input}
            type="text"
            placeholder={t("search")}
            value={searchVal}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              if (!user?.id) return
              setSearchVal(e.target.value)
            }}
          />

          <LoupeIcon className={styles.search__searchIcon} />

          {withClear && searchVal && (
            <button type={"button"} className={styles.search__btnClear} onClick={handleClear}>
              <CloseCircleIcon2 />
            </button>
          )}
        </>
      )}
    </div>
  )
}

export default Search
