export const CloseCircleIcon2 = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6ZM6 6.84853L3.7576 9.09093L2.90907 8.2424L5.15147 6L2.90907 3.7576L3.7576 2.90907L6 5.15147L8.2424 2.90907L9.09093 3.7576L6.84853 6L9.09093 8.2424L8.2424 9.09093L6 6.84853Z"
      />
    </svg>
  )
}
