import { useEffect, useMemo, useState } from "react"
import ArticleListAccordion from "../ArticleListAccordion/ArticleListAccordion"
import ArticleItemAccordion from "../ArticleItemAccordion/ArticleItemAccordion"
import { TreeUpIcon } from "../../../icons/TreeUpIcon"
import { TreeDownIcon } from "../../../icons/TreeDownIcon"
import LocalizedLink from "../../../hoc/LocalizedLink"
import ScrollBlock from "../../Assets/ScrollBlock"
import { IArticle, IExArticle } from "../../../types/content"
import { useAppSelector } from "../../../hooks"
import { selectArticles } from "../../../redux/slice/articles"
import useWindowSize from "../../../hooks/useWindowSize"
import Accordion from "../../Assets/Accordion/Accordion"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import styles from "./ArticlesList.module.scss"

function insertDataByIdRecursive(arr: any, id: string, dataToInsert: IExArticle) {
  for (const item of arr) {
    if (item.id === id) {
      item.children.push(dataToInsert)
      return true
    } else if (item.children && item.children.length > 0) {
      const isInserted = insertDataByIdRecursive(item.children, id, dataToInsert)
      if (isInserted) return true
    }
  }
  return false
}
const deepFindIsOpen = (parentArr: IExArticle[]): IExArticle | null => {
  const needEl = parentArr.find((el) => el.isOpen)
  let isFind = false
  if (needEl) return needEl

  let result: IExArticle | null = null
  parentArr.forEach((child) => {
    if (child?.children?.length) {
      child?.children?.forEach((childItem: IExArticle) => {
        if (!isFind) {
          result = deepFindIsOpen(childItem.children)
          if (result) isFind = true
        }
      })
    }
  })
  return result
}

const toggleArticlesListInAsideLocal = (aside: IExArticle[]) => {
  if (!aside) return null
  const isOpenExist = deepFindIsOpen(aside)?.isOpen
  const newAside = aside.map((article) => {
    // 1й элемент
    article.isOpen = !isOpenExist
    // 1я вложенность
    if (article.children.length > 0) {
      article.children.map((articleChild) => {
        articleChild.isOpen = !isOpenExist
        // 2я вложенность
        if (articleChild.children.length > 0) {
          articleChild.children.map((childItem) => {
            childItem.isOpen = !isOpenExist
          })
        }
      })
    }
    return article
  })
  localStorage.setItem("articleList", JSON.stringify(newAside))
  return newAside
}

const ArticlesList = ({ articles }: { articles: IArticle[] }) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const params = useParams()
  const { favoriteArticles } = useAppSelector(selectArticles)
  const { isDesktop } = useWindowSize()

  const [maxHeight, setMaxHeight] = useState<number | undefined>(undefined)
  const [isGlobalOpen, setGlobalOpen] = useState<boolean>(false)
  const [aside, setAside] = useState<IExArticle[] | null>(null)

  useEffect(() => {
    // устанавливаем данные с localStorage в хук aside при заходе на страницу
    const articlesAsideListLocal = localStorage.getItem("articleList")
    const articlesAsideList: IExArticle[] | null = articlesAsideListLocal ? JSON.parse(articlesAsideListLocal) : null
    if (articlesAsideList) setAside(articlesAsideList)
  }, [])

  useEffect(() => {
    // если в localeStorage нет aside-списка, то устанавливаем его
    const articlesAsideIDsLocal = localStorage.getItem("articlesIDs")
    const articlesAsideIDs: string[] = articlesAsideIDsLocal ? JSON.parse(articlesAsideIDsLocal) : null
    // если есть IDs в localStorage, и длины массивов совпадают, и нет новой article, то делаем выход
    if (articlesAsideIDs) {
      // если длины массиво совпадают, то проверям, нет ли новой статьи(ищем по её id)
      if (articlesAsideIDs?.length === articles?.length) {
        const newArticle = articles.filter((article) => !articlesAsideIDs.includes(article.id))
        if (!newArticle?.length) return
      }
    }

    const isOpenedFn = (article: IArticle): boolean => {
      const isOpened =
        params.id === article.id ||
        articles
          .filter((artF) => artF.parent_id === article.id)
          .some((art) => {
            // ищем 1ю вложенность
            if (art.id === params.id) {
              return true
            } else {
              // ищем 2ю вложенность
              const inner = articles.find((artI) => artI.parent_id === art.id)
              if (inner) {
                return inner.id === params.id
              }
            }
          })
      return isOpened
    }

    // Родительские статьи
    const output: IExArticle[] = articles
      .filter((article: IArticle) => !article.parent_id)
      .map((article: IArticle) => {
        return {
          ...article,
          isOpen: isOpenedFn(article),
          children: [],
        }
      })

    // Присвоение дочерних статей
    articles
      .filter((article) => article.parent_id)
      .forEach((article) => {
        insertDataByIdRecursive(output, String(article.parent_id), {
          ...article,
          isOpen: isOpenedFn(article),
          children: [],
        })
      })

    const uniqueIds = [...new Set(articles?.map((article) => article.id))]
    localStorage.setItem("articleList", JSON.stringify(output))
    localStorage.setItem("articlesIDs", JSON.stringify(uniqueIds))
    setAside(output)
  }, [articles, params])

  const articlesList = useMemo(() => {
    if (!aside) return
    const recursiveInsertArticleContent = (article: any) => {
      if (article?.children?.length) {
        return (
          <ArticleListAccordion
            key={article.id}
            name={article.text}
            href={article.id}
            isArticleOpen={article.isOpen}
            setAside={setAside}
          >
            {article.children.map((i: any) => {
              return recursiveInsertArticleContent(i)
            })}
          </ArticleListAccordion>
        )
      } else {
        return (
          <ArticleItemAccordion
            key={article.id}
            txt={article.text}
            href={article.id}
            isFavorite={Boolean(favoriteArticles?.find((i) => i.id === article.id))}
          />
        )
      }
    }

    return (
      <div className={styles.inner}>
        {aside?.map((article, index) => {
          return (
            <ArticleListAccordion
              key={index}
              name={article.text}
              href={article.id}
              isArticleOpen={article.isOpen}
              isChildren={article?.children?.length > 0}
              setAside={setAside}
            >
              {article?.children?.map((childArticle: any) => recursiveInsertArticleContent(childArticle))}
            </ArticleListAccordion>
          )
        })}
      </div>
    )
  }, [aside, favoriteArticles, params])

  const globalTrigger = () => {
    setGlobalOpen((prev) => !prev)
    const newAside = aside ? toggleArticlesListInAsideLocal(aside) : null
    if (newAside) setAside(newAside)
  }

  useEffect(() => {
    function setMax() {
      const windowHeight = window.innerHeight
      const headerHeight = (document.querySelector(".header") as HTMLDivElement)?.offsetHeight
      // высота экрана - высота хедера - отступы у aside сверху/снизу - высота кнопки Назад(на Главную) - хедер aside
      setMaxHeight(windowHeight - headerHeight - 50 - 72 - 44)
    }
    setMax()

    window.addEventListener("resize", setMax)
    return () => window.removeEventListener("resize", setMax)
  }, [])

  useEffect(() => {
    if (!aside) return
    const isOpenExist = deepFindIsOpen(aside)?.isOpen
    setGlobalOpen(isOpenExist || false)
  }, [aside])

  return (
    <div className={styles.aside}>
      {isDesktop ? (
        <>
          <header className={styles.header}>
            <LocalizedLink to={"/articles"} className={styles.link}>
              {t(isDesktop ? "toMain" : "allArticles")}
            </LocalizedLink>
            <button onClick={globalTrigger} className={styles.btn}>
              {isGlobalOpen ? <TreeUpIcon /> : <TreeDownIcon />}
            </button>
          </header>

          <ScrollBlock hideTracksWhenNotNeeded autoHeightMax={maxHeight}>
            {articlesList}
          </ScrollBlock>
        </>
      ) : (
        <Accordion header={t("allArticles")} className={styles["order-descr-accord"]} defaultOpen={false}>
          <ScrollBlock hideTracksWhenNotNeeded autoHeightMax={maxHeight}>
            {articlesList}
          </ScrollBlock>
        </Accordion>
      )}
    </div>
  )
}

export default ArticlesList
