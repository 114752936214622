// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PageNotFound_wrap__dj4sS {
  min-height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
@media (min-width: 640px) {
  .PageNotFound_wrap__dj4sS {
    min-height: 100%;
  }
}

.PageNotFound_description__\\+dI\\+4 {
  font-size: var(--fz-lg);
  line-height: var(--lh-xlg);
  max-width: 470px;
  margin: 0 auto 7px;
}

.PageNotFound_num__vpoxk {
  color: var(--accent-color);
  opacity: 0.5;
  font-size: 128px;
  line-height: 146px;
  font-weight: 700;
  letter-spacing: 17px;
  margin-bottom: 7px;
}

.PageNotFound_note__yMbsR {
  font-size: var(--fz-md);
  line-height: var(--lh-md);
  color: #7A808B;
  margin-bottom: 42px;
}`, "",{"version":3,"sources":["webpack://./src/components/PageNotFound/PageNotFound.module.scss"],"names":[],"mappings":"AAAA;EACE,+BAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;AACF;AACE;EAPF;IAQI,gBAAA;EAEF;AACF;;AACA;EACE,uBAAA;EACA,0BAAA;EACA,gBAAA;EACA,kBAAA;AAEF;;AACA;EACE,0BAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;EACA,oBAAA;EACA,kBAAA;AAEF;;AACA;EACE,uBAAA;EACA,yBAAA;EACA,cAAA;EACA,mBAAA;AAEF","sourcesContent":[".wrap {\r\n  min-height: calc(100vh - 100px);\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  text-align: center;\r\n\r\n  @media (min-width: 640px) {\r\n    min-height: 100%;\r\n  }\r\n}\r\n\r\n.description {\r\n  font-size: var(--fz-lg);\r\n  line-height: var(--lh-xlg);\r\n  max-width: 470px;\r\n  margin: 0 auto 7px;\r\n}\r\n\r\n.num {\r\n  color: var(--accent-color);\r\n  opacity: 0.5;\r\n  font-size: 128px;\r\n  line-height: 146px;\r\n  font-weight: 700;\r\n  letter-spacing: 17px;\r\n  margin-bottom: 7px;\r\n}\r\n\r\n.note {\r\n  font-size: var(--fz-md);\r\n  line-height: var(--lh-md);\r\n  color: #7A808B;\r\n  margin-bottom: 42px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `PageNotFound_wrap__dj4sS`,
	"description": `PageNotFound_description__+dI+4`,
	"num": `PageNotFound_num__vpoxk`,
	"note": `PageNotFound_note__yMbsR`
};
export default ___CSS_LOADER_EXPORT___;
