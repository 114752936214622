import { FC } from "react"
import LocalizedLink from "../../hoc/LocalizedLink"
import { ArrowLeftBoldIcon } from "../../icons/ArrowLeftBoldIcon"
import clsx from "clsx"
import styles from "./TitleBack.module.scss"
import { useDispatch } from "react-redux"
import { setMobileMenuIsHidden } from "../../redux/slice/isMoreModal"
import { useNavigate } from "react-router-dom"

interface Props {
  title?: string
  searchVal?: string | number
  setSearchVal?: any
  onClick?: any
  noLinkBack?: boolean
  className?: string
  link?: string
}

const TitleBack: FC<Props> = ({ title, setSearchVal, searchVal, onClick, noLinkBack, link, className }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  window.addEventListener("popstate", () => {
    dispatch(setMobileMenuIsHidden(false))
  })

  return (
    <div className={clsx(styles.titleBack, className)}>
      {noLinkBack ? (
        <div
          className={`titleBack__back ${styles.titleBack__back}`}
          onClick={() => {
            if (typeof onClick === "function") {
              onClick()
            } else {
              navigate("/")
            }
          }}
        >
          <ArrowLeftBoldIcon />
        </div>
      ) : (
        <LocalizedLink
          className={`titleBack__back ${styles.titleBack__back}`}
          to={link || "/"}
          onClick={() => {
            if (searchVal) setSearchVal("")
            // if (location.pathname !== "/") navigate("/")
          }}
        >
          <ArrowLeftBoldIcon />
        </LocalizedLink>
      )}

      <h2 className={styles.titleBack__text}>{title}</h2>
    </div>
  )
}

export default TitleBack
