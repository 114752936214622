import { useAppDispatch, useAppSelector } from "./index"
import {
  addChatToTop,
  addMessage,
  addMessages,
  changeMessage,
  clearChats,
  deleteChat,
  deleteMessage,
  selectChat,
  setChats,
  setChatsEnd,
  setChatsInit,
  setMessagesLoaded,
  updateChatThunk,
} from "../redux/slice/chat"
import { useLazyGetChatListQuery, useLazyGetChatMessagesQuery } from "../redux/api/chat"
import { useEffect } from "react"
import { selectUser } from "../redux/slice/auth"
import { EMPTY_LAST_MESSAGE, LIMIT_CHATS_COUNT } from "../utils/constants"
import { IChatInfo, ILastMessage } from "../types/chat"

const useChats = () => {
  const dispatch = useAppDispatch()
  const appDispatch = useAppDispatch()
  const user = useAppSelector(selectUser)
  const { id: userID } = user || {}
  const { chats, isChatsInit } = useAppSelector(selectChat)

  const [getChatList] = useLazyGetChatListQuery()
  const [getChatMessages] = useLazyGetChatMessagesQuery()

  const initializeChats = (data: { aDialogs: IChatInfo[]; bIsEnd: boolean; iCount: number }) => {
    const dataChats = data?.aDialogs || []
    dispatch(
      setChats(
        dataChats.map((chat) => {
          return { ...chat, messages: [], isMessagesLoaded: false, isVisible: true, isInitial: true }
        }),
      ),
    )
    dispatch(setChatsEnd(Boolean(data?.bIsEnd)))
    if (!dataChats?.length) return
    getChatMessages(dataChats[0].id).then((res) => {
      if (!res?.data?.aMessages?.length) return
      dispatch(addMessages({ chatID: dataChats[0].id, messages: res.data.aMessages }))
      dispatch(setMessagesLoaded({ chatID: dataChats[0].id, isLoaded: true }))
    })
    if (dataChats?.length < 2) return
    getChatMessages(dataChats[1].id).then((res) => {
      if (!res?.data?.aMessages?.length) return
      dispatch(addMessages({ chatID: dataChats[1].id, messages: res.data.aMessages }))
      dispatch(setMessagesLoaded({ chatID: dataChats[1].id, isLoaded: true }))
    })
  }

  const addMessageBySocket = (event: { dialog: IChatInfo; message: ILastMessage }) => {
    appDispatch(
      updateChatThunk({
        chatID: event.dialog.id,
        chatInfo: event.dialog,
        data: {
          last_message: event.dialog.last_message,
        },
      }),
    )
    dispatch(addMessage({ chatID: event.dialog.id, message: event.message }))
  }

  const changeMessageBySocket = (event: ILastMessage) => {
    dispatch(
      changeMessage({
        chatID: event.chat_dialog_id,
        message: event,
      }),
    )
  }

  const deleteMessageBySocket = (event: ILastMessage) => {
    const isMessagesLoaded = chats.find((chat) => chat.id === event.chat_dialog_id)?.isMessagesLoaded
    if (isMessagesLoaded) {
      // если сообщения в чате уже были загружены заранее, то удаляем из messages и меняем last_message
      dispatch(
        deleteMessage({
          chatID: event.chat_dialog_id,
          message: event,
        }),
      )
    } else {
      // если сообщения еще не были загружены, то отправляем запрос на их получение
      getChatMessages(event.chat_dialog_id).then((res) => {
        const messages = res?.data?.aMessages
        dispatch(
          deleteMessage({
            chatID: event.chat_dialog_id,
            message: event,
            newLastMessage: messages && messages.length ? messages[messages.length - 1] : EMPTY_LAST_MESSAGE,
          }),
        )
      })
    }
  }

  const addNewChatBySocket = (event: IChatInfo) => {
    if (!event?.id || !event?.last_message?.id) return
    dispatch(addChatToTop({ ...event, messages: [], isMessagesLoaded: false, isVisible: true, isInitial: true }))
  }

  const removeChatBySocket = (event: { id: string }) => {
    if (!event?.id) return
    dispatch(deleteChat(event.id))
  }

  useEffect(() => {
    if (!userID) {
      dispatch(clearChats())
      return
    }
    if (isChatsInit) return

    dispatch(setChatsInit(true))
    getChatList({ limit: LIMIT_CHATS_COUNT })
      .unwrap()
      .then((res) => {
        initializeChats(res)
      })
      .catch((err) => {
        console.error("err in getChatList: ", err)
      })

    if (typeof window.Echo === "undefined") return

    const channel = window.Echo.private(`privateUser.${userID}`)
    // пришло новое сообщение, обновляем last_message в списке чатов, делаем его не прочитанным, если этот чат не активен
    channel?.listen(".newMessageInDialog", (event: { dialog: IChatInfo; message: ILastMessage }) => {
      addMessageBySocket(event)
    })
    // сообщение отредактировано
    channel?.listen(".changeMessageInDialog", (event: ILastMessage) => changeMessageBySocket(event))
    // сообщение удалено
    channel?.listen(".deleteMessageInDialog", (event: ILastMessage) => {
      deleteMessageBySocket(event)
    })
    // добавление диалога в список
    channel?.listen(".newChatDialog", (event: IChatInfo) => addNewChatBySocket(event))
    // удаление диалога из списка
    channel?.listen(".removeChatDialog", (event: { id: string }) => removeChatBySocket(event))
  }, [isChatsInit, userID])
}

export default useChats
