import clsx from "clsx"
import styles from "./Coordinators.module.scss"
import { DoubleCheckIcon } from "../../icons/DoubleCheckIcon"
import { ProgressCircle } from "../../icons/ProgressCircle"
import { TimeLightIcon } from "../../icons/TimeLightIcon"
import { CloseCircleIcon } from "../../icons/CloseCircleIcon"
import { GetUserAvatar } from "../Assets/GetUserAvatar/GetUserAvatar"
import { IApprovals, IApprovalAssign } from "../../types/orderTypes"
import { Fragment } from "react"
import { InfoIcon } from "../../icons/InfoIcon"
import { renderToString } from "react-dom/server"
import moment from "moment"
import { useTranslation } from "react-i18next"

interface Props {
  className?: string
  approveSteps?: IApprovals[]
}

const Coordinators = ({ className, approveSteps }: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })

  const StatusBlock = ({ id }: { id: "waiting" | "in_progress" | "approved" | "denied" | "canceled" }) => {
    let statusInfo = ""
    let icon = undefined

    switch (id) {
      case "approved":
        statusInfo = "resolve"
        icon = <DoubleCheckIcon />
        break
      case "denied":
        statusInfo = "reject"
        icon = <CloseCircleIcon />
        break
      case "in_progress":
        statusInfo = "progress"
        icon = <ProgressCircle />
        break
      case "waiting":
        statusInfo = "wait"
        icon = <TimeLightIcon />
        break
      default:
    }

    return <div className={clsx(styles["coordinator__status"], styles[`status--${statusInfo}`])}>{icon}</div>
  }

  const isRemove = (approveStep: IApprovals, coordinator: IApprovalAssign): boolean => {
    return (
      (approveStep.type === "majority" || approveStep.type === "one") &&
      approveStep.status === "approved" &&
      coordinator.status === "canceled"
    )
  }

  const createTooltipContent = (coordinator: IApprovalAssign): string => {
    if (coordinator.status === "waiting") return t("notReceivedForApproval")

    const leftInfoArr: any[] = []
    const rightInfoArr: any[] = []

    switch (coordinator.status) {
      case "approved":
        leftInfoArr.push(t("approved"))
        if (coordinator.aproved_at) rightInfoArr.push(moment.unix(coordinator.aproved_at).format("HH:mm DD.MM.YYYY"))
        break
      case "denied":
        leftInfoArr.push(t("rejected"))
        if (coordinator.aproved_at) rightInfoArr.push(moment.unix(coordinator.aproved_at).format("HH:mm DD.MM.YYYY"))
        break
      case "in_progress":
        leftInfoArr.push(t("readed"))
        rightInfoArr.push(
          coordinator.viewed_at ? moment.unix(coordinator.viewed_at).format("HH:mm DD.MM.YYYY") : t("no"),
        )
        // Тут добавить условие, когда добавят "Срок согласования"
        break
      case "canceled":
        leftInfoArr.push(t("rejected"))
        if (coordinator.aproved_at) rightInfoArr.push(moment.unix(coordinator.aproved_at).format("HH:mm DD.MM.YYYY"))
        break
      default:
    }

    const content = () => {
      return (
        <div className={styles["tooltip_content"]}>
          <div className={styles["tooltip_left-info"]}>
            <span>{t("received")}</span>
            {leftInfoArr.map((info, i) => (
              <span key={i}>{info}</span>
            ))}
          </div>
          <div className={styles["tooltip_right-info"]}>
            <span>{moment.unix(coordinator.sended_at).format("HH:mm DD.MM.YYYY")}</span>
            {rightInfoArr.map((info, i) => (
              <span key={i}>{info}</span>
            ))}
          </div>
        </div>
      )
    }

    const html = renderToString(content())

    return `<div>${html}</div>`
  }

  return (
    <>
      <div className={clsx(className, styles["coordinators"])}>
        {approveSteps?.map((approveStep: IApprovals, i) => (
          <Fragment key={i}>
            {approveStep.assignees.map((coordinator: IApprovalAssign, k: number) => (
              <Fragment key={k}>
                {isRemove(approveStep, coordinator) ? null : (
                  <div className={styles["coordinator"]}>
                    <GetUserAvatar
                      avatar={coordinator.concordant.avatar_id}
                      name={coordinator.concordant.name}
                      className={styles["coordinator__avatar"]}
                    />
                    <div
                      className={clsx(
                        styles["coordinator__info"],
                        coordinator.status === "waiting" && styles["coordinator__disabled"],
                      )}
                    >
                      <p className={styles["coordinator__name"]}>{coordinator.concordant.name}</p>
                      {coordinator.comment && <p className={styles["coordinator__comment"]}>{coordinator.comment}</p>}
                    </div>
                    <div className={styles["status-container"]}>
                      <div
                        data-tooltip-id={"globalTooltip"}
                        data-tooltip-delay-show={300}
                        data-tooltip-place={"top"}
                        data-tooltip-class-name={styles.tooltip_position}
                        data-tooltip-html={createTooltipContent(coordinator)}
                        data-tooltip-offset={15}
                        className={styles["tooltip_icon-container"]}
                      >
                        <InfoIcon />
                      </div>
                      <StatusBlock id={coordinator.status} />
                    </div>
                  </div>
                )}
              </Fragment>
            ))}
          </Fragment>
        ))}
      </div>
    </>
  )
}

export default Coordinators
